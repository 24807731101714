import React from 'react';

import Link from 'next/link';

export default function PageShell({ children }) {
  return (
    <div className="container">
      <div className="py-10 space-y-8">{children}</div>
    </div>
  );
}

const Header = ({ title, children, backButtonRoute }) => (
  <header className="flex flex-col md:flex-row md:justify-between md:items-center space-y-4 md:space-y-0 md:space-x-4">
    <div className="space-y-2">
      {backButtonRoute && (
        <Link href={backButtonRoute}>
          <a className="text-sm">← Back</a>
        </Link>
      )}
      <h1 className="text-3xl font-bold text-gray-900">{title}</h1>
    </div>

    <div className="shrink-0 flex space-x-4">{children}</div>
  </header>
);

const Body = (props) => <main className="space-y-8" {...props} />;

PageShell.Header = Header;
PageShell.Body = Body;
