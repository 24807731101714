import React from 'react';

import Link from 'next/link';
import { useRouter } from 'next/router';

import clsx from 'clsx';
import { NAVIGATION } from 'config';

import { logout } from 'lib/graphql';

import Header from 'components/Header';
import MobileMenu from 'components/MobileMenu';
import { notify } from 'components/Notifications';

const DesktopNavigation = () => {
  const router = useRouter();

  return (
    <nav className="hidden md:flex md:grow md:self-stretch space-x-4 px-8">
      {NAVIGATION.map(({ label, route, matchRoutes }, idx) => (
        <Link key={idx} href={route}>
          <a
            key={idx}
            className={clsx(
              matchRoutes?.includes(router.pathname) ||
                router.pathname === route ||
                (router.pathname.startsWith(route) && route !== '/')
                ? 'text-black border-spoto'
                : 'text-gray-500 border-transparent hover:text-black transition',
              'group flex items-center px-2 py-2 text-sm font-semibold border-b'
            )}
          >
            {label}
          </a>
        </Link>
      ))}
    </nav>
  );
};

export default function Layout({ children, headerProps }) {
  const [show, setShow] = React.useState(false);

  const { push } = useRouter();

  const handleLogout = async () => {
    const logoutPromise = logout();

    notify.promise(logoutPromise, {
      loading: 'Signing out...',
      success: 'You have been signed out',
      error: "We couldn't sign you out",
    });

    const loggedOut = await logoutPromise;

    if (loggedOut) {
      push('/auth/login');
    }
  };

  return (
    <>
      <div className="flex flex-col min-h-100vh">
        <Header
          onShowMenu={() => setShow(true)}
          onLogout={handleLogout}
          sticky
          {...headerProps}
        >
          <DesktopNavigation />
        </Header>
        <MobileMenu
          show={show}
          onClose={() => setShow(false)}
          onLogout={handleLogout}
        />
        <main className="flex flex-col flex-1">{children}</main>
      </div>
    </>
  );
}

export const getLayout = (page) => <Layout>{page}</Layout>;
