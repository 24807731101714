import React from 'react';

import Link from 'next/link';
import { useRouter } from 'next/router';

import clsx from 'clsx';
import { NAVIGATION } from 'config';
import useUser from 'ctx/useAuth';
import useScrollLock from 'ctx/useScrollLock';

import Drawer from './Drawer';
import Logo from './Logo';

export default function MobileMenu({ show, onClose = () => {}, onLogout }) {
  const router = useRouter();
  const { user } = useUser();

  useScrollLock(show);

  const handleLogout = () => {
    onLogout();
    onClose();
  };

  return (
    <Drawer show={show} onClose={onClose}>
      <Drawer.Body>
        <div className="px-4">
          <Logo width="100" />
        </div>
        <div className="pt-6 pb-3 space-y-1">
          {NAVIGATION.map(({ label, route, matchRoutes }, idx) => (
            <Link key={idx} href={route}>
              <a
                key={idx}
                onClick={onClose}
                className={clsx(
                  matchRoutes?.includes(router.pathname) ||
                    router.pathname === route ||
                    (router.pathname.startsWith(route) && route !== '/')
                    ? 'bg-gray-50 border-spoto'
                    : 'border-transparent text-gray-500',
                  'block pl-3 pr-4 py-2 border-l-4 text-base font-medium'
                )}
              >
                {label}
              </a>
            </Link>
          ))}
        </div>
        <div className="pt-4 pb-3 border-t border-gray-200">
          <div className="flex items-center px-4">
            <div className="text-base font-medium text-gray-800">
              {user?.firstName} {user?.lastName}
            </div>
          </div>
          <div as="div" className="mt-3 space-y-1">
            <button
              onClick={handleLogout}
              className="block px-4 py-2 text-base font-medium text-gray-500"
            >
              Sign Out
            </button>
          </div>
        </div>
      </Drawer.Body>
    </Drawer>
  );
}
