import clsx from 'clsx';
import times from 'lodash/times';

export const SkeletonText = ({ className = 'h-4' }) => (
  <div className={clsx('rounded-sm bg-gray-100 animate-pulse', className)} />
);

export const SkeletonDisplayText = ({ size = 'md', className }) => (
  <div
    className={clsx(
      'relative w-full rounded-sm bg-gray-100 animate-pulse max-w-[128px]',
      size === 'sm' && 'h-4',
      size === 'md' && 'h-5',
      size === 'lg' && 'h-6',
      size === 'xl' && 'h-10',
      className
    )}
  />
);

export const SkeletonBodyText = ({ lines = 3, className }) => (
  <div className="w-full space-y-2 animate-pulse">
    {times(lines, (index) => (
      <div
        key={index}
        className={clsx(
          'relative h-3 rounded-sm bg-gray-100',
          lines > 1 && 'last:w-4/5',
          className
        )}
      />
    ))}
  </div>
);

export const SkeletonThumbnail = ({ size = 'medium', className }) => (
  <div
    className={clsx(
      'relative w-full rounded-sm bg-gray-100 animate-pulse',
      size === 'small' && 'w-12 h-12',
      size === 'medium' && 'w-24 h-24',
      size === 'large' && 'w-32 h-32',
      className
    )}
  />
);

export const SkeletonImage = ({ aspect = 'aspect-[9/5]', className }) => (
  <div
    className={clsx(
      'relative w-full bg-gray-100 animate-pulse',
      aspect,
      className
    )}
  />
);
