import React from 'react';

import { Menu, Transition } from '@headlessui/react';
import clsx from 'clsx';
import useUser from 'ctx/useAuth';
import UserIcon from 'images/solid/user.svg';

import { LinkedLogo } from 'components/Logo';

import { SkeletonText } from './Skeleton';

const User = ({ onLogout }) => {
  const { user } = useUser();

  if (!user) {
    return (
      <div className="max-w-xs bg-white flex items-center text-sm rounded-md text-gray-700">
        <UserIcon className="mr-2 text-gray-250" />
        <SkeletonText className="h-5 w-24" />
      </div>
    );
  }

  return (
    <Menu as="div" className="ml-3 relative">
      <div>
        <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-md text-gray-700">
          <span className="sr-only">Open user menu</span>
          <UserIcon className="mr-2" />
          {user.firstName} {user.lastName}
        </Menu.Button>
      </div>
      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <Menu.Item>
            <button
              onClick={onLogout}
              className="block w-full text-left px-4 py-2 text-sm text-gray-700"
            >
              Sign Out
            </button>
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default function Header({
  show = true,
  children,
  sticky,
  onShowMenu = () => {},
  onLogout,
}) {
  if (!show) return null;

  return (
    <div
      className={clsx('border-b bg-white', sticky && 'sticky top-0 z-[500]')}
    >
      <div className="container">
        <div className="flex justify-between items-center h-[56px]">
          <div className="flex shrink-0">
            <LinkedLogo />
          </div>

          {children}

          <div className="shrink-0 flex justify-end md:hidden">
            <button type="button" onClick={onShowMenu}>
              Menu
            </button>
          </div>
          <div className="shrink-0 hidden md:flex md:justify-end ">
            <User onLogout={onLogout} />
          </div>
        </div>
      </div>
    </div>
  );
}
