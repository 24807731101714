export const NAVIGATION = [
  {
    label: 'Dashboard',
    route: '/',
  },

  {
    label: 'Employees',
    route: '/employees',
  },

  {
    label: 'Help',
    route: '/help',
  },
];
